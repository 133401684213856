import React from 'react';
import { Typography } from '@mui/material';

const DicomScoresColumns = [
  {
    field: 'date',
    headerName: 'Fecha',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.date}</Typography>
    ),
  },
  {
    field: 'score',
    headerName: 'Puntaje',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.score}</Typography>
    ),
  },
];

export default DicomScoresColumns;
