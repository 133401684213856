import React from 'react';
import {
  InsertDriveFile,
  Description,
  Create,
  Assignment,
  AccountBalance,
  TrendingUp,
} from '@mui/icons-material';
import { FETCH_COMPANY_CTE, FETCH_COMPANY_TGR, GET_COMPANY_ERUT } from '../graphql';
import DocumentChip from '../components/cells/DocumentsChip';
import CTEandTGRDocumentChip from '../components/cells/CTEandTGRDocumentChip';
import { GenerateContractChip } from '../components/contractGeneration';

export const HISTORIC_DOCUMENTS = ['balance', 'eerr'];

export const DOCUMENT_MAPPER = [
  {
    Component: GenerateContractChip,
    documentType: 'generated_contract',
    icon: <Create />,
    label: 'G. Contrato',
    mutation: FETCH_COMPANY_TGR,
  },
  {
    Component: DocumentChip,
    documentType: 'contract',
    icon: <Assignment />,
    label: 'Contrato',
  },
  {
    Component: DocumentChip,
    documentType: 'balance',
    icon: <AccountBalance />,
    label: 'Balance',
  },
  {
    Component: DocumentChip,
    documentType: 'eerr',
    icon: <TrendingUp />,
    label: 'EERR',
  },
  {
    Component: DocumentChip,
    documentType: 'contactability',
    icon: <TrendingUp />,
    label: 'Contactabilidad',
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'tgr',
    icon: <InsertDriveFile />,
    label: 'TGR',
    mutation: FETCH_COMPANY_TGR,
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'cte',
    icon: <Description />,
    label: 'CTE',
    mutation: FETCH_COMPANY_CTE,
  },
  {
    Component: CTEandTGRDocumentChip,
    documentType: 'erut',
    icon: <Description />,
    label: 'E-Rut',
    mutation: GET_COMPANY_ERUT,
  },
];
