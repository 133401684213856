import React from 'react';
import { Typography } from '@mui/material';

const DicomExternalQueriesColumns = [
  {
    field: 'name',
    headerName: 'Nombre',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.businessName}</Typography>
    ),
  },
  {
    field: 'use',
    headerName: 'Uso',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.use ?? 'n/d'}</Typography>
    ),
  },
  {
    field: 'platform',
    headerName: 'Plataforma',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.platform ?? 'n/d'}</Typography>
    ),
  },
  {
    field: 'date',
    headerName: 'Fecha',
    type: 'string',
    sortable: true,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.date}</Typography>
    ),
  },
];

export default DicomExternalQueriesColumns;
