import { Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { CompanyCell, MoneyCell } from '../components/cells';
import { formatDateWordsWithYear } from '../helpers';

const creditLineMovementColumns = [
  {
    field: 'id',
    headerName: 'Fecha',
    sortable: true,
    width: 180,
    valueGetter: ({ row }) => row.createdAt,
    renderCell: ({ value }) => (
      <Typography>{formatDateWordsWithYear(moment(value))}</Typography>
    ),
  },
  {
    field: 'movementType',
    headerName: 'Tipo',
    sortable: false,
    width: 90,
    renderCell: ({ value }) => <Typography>{value}</Typography>,
  },
  {
    field: 'amount',
    headerName: 'Monto',
    sortable: false,
    width: 90,
    renderCell: ({ value }) => <MoneyCell amount={value} />,
  },
  {
    field: 'creditLine_MasterEntity_Name',
    headerName: 'Línea',
    sortable: true,
    width: 180,
    valueGetter: (params) => params.row.creditLine.masterEntity,
    renderCell: ({ value }) => (
      <CompanyCell
        company={value}
        showDicom={false}
        showBlacklist={false}
      />
    ),
  },
];

export default creditLineMovementColumns;
