import React from 'react';
import { GenericTooltipCell } from '../components/cells';

export const contactSourceToText = {
  LUCILA: 'Fingo',
  PROVIDED_BY_CLIENT: 'Cliente',
};

export const contactTypeToText = {
  COMMERCIAL_KEY: 'Sí',
  COMMERCIAL: 'No',
};

export const CONTACT_COLUMNS = [
  {
    field: 'name',
    headerName: 'Nombre',
    type: 'string',
    minWidth: 100,
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'position',
    headerName: 'Cargo',
    type: 'string',
    minWidth: 120,
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
    type: 'string',
    minWidth: 100,
    align: 'center',
    editable: true,
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Correo',
    type: 'string',
    minWidth: 140,
    align: 'center',
    editable: true,
    flex: 3,
    renderCell: ({ row }) => (<GenericTooltipCell data={row.email} />),
  },
  {
    field: 'source',
    headerName: 'Fuente',
    type: 'singleSelect',
    minWidth: 120,
    align: 'center',
    valueOptions: ['PROVIDED_BY_CLIENT', 'LUCILA'],
    valueFormatter: ({ value }) => contactSourceToText[value],
    editable: true,
    flex: 1,
  },
];
